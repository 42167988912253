import useResizeObserver from '@react-hook/resize-observer';
import * as API from 'apis/api';
import { useNotifications } from 'components/notificationSystem/notificationContext';
import ProductResultCanvas from "components/productResultCanvas/productResultCanvas";
import { JuneEvents, trackJune } from 'helpers/hooks/useJune';
import React, { useContext, useEffect, useRef } from "react";
import { useLoaderData, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { DocControlPanel } from './components/docControlPanel';
import { ResultGallery } from './components/gallery';
import { ResultFooter } from './components/resultFooter';
import { ResultList } from './components/resultList';
import { StageContext, StageProvider } from './stageContext';

export function ResultRoute({ analytics, setAlertData }) {
    const [addSimpleAnnotationMode, setAddSimpleAnnotationMode] = React.useState(false);
    const loaderData = useLoaderData()
    const location = useLocation()
    const navigate = useNavigate()

    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams()

    const [currentDoc, setCurrentDoc] = React.useState(null)
    const [inferenceResult, setInferenceResult] = React.useState(null)
    const [currentUseCase, setCurrentUseCase] = React.useState(null)
    const [currentFile, setCurrentFile] = React.useState(null)

    const [justSeen, setJustSeen] = React.useState(false)

    const notification = useNotifications()


    const navigateTo = (src, replace = false, state = null) => {
        let options = {
            replace: replace
        }
        if (state)
            options['state'] = state
        navigate(src, options)
    }


    const [navigateToDashboard, setNavigateToDashboard] = React.useState(false)


    useEffect(() => {
        if (navigateToDashboard) navigate('/', { replace: true })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigateToDashboard])

    const initializeUseCase = async () => {
        await API.getUseCase(loaderData['usecaseID']).then((res) => {
            setCurrentUseCase(res)
        }).catch((e) => {
            notification.addNotification({
                title: 'Use Case Error',
                message: "Can't find selected Use Case",
                severity: 'Error',
                timeout: 3000
            })
            setNavigateToDashboard(true)
        })
    }

    const initializeDoc = async () => {
        await API.getDocument(loaderData['usecaseID'], loaderData['documentID']).then((res) => {
            /*
             if (res.revision_status === 'pending') {
                 updateFileStatus('to_review', false)
             }
             */
            setCurrentDoc(res)
        }).catch((e) => {
            notification.addNotification({
                title: 'Document Error',
                message: "Can't find selected Document",
                severity: 'Error',
                timeout: 3000
            })

            setNavigateToDashboard(true)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    React.useEffect(() => {

        if (location.state)
            setCurrentUseCase(location.state['currentUseCase'])
        else
            initializeUseCase()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    React.useEffect(() => {
        if (!currentUseCase) return;
        initializeDoc()
    }, [currentUseCase, location])


    React.useEffect(() => {
        if (!currentDoc) return;
        if (!currentFile && currentDoc && Object.keys(currentDoc.files).length > 0) {

            searchParams.set('file', Object.keys(currentDoc.files)[0])
            setInferenceResult(null);
            navigate({ pathname: `/usecase/${loaderData['usecaseID']}/result/${loaderData['documentID']}`, search: '?' + searchParams.toString() }, { state: { 'currentUseCase': currentUseCase }, replace: true })
            setCurrentFile(Object.keys(currentDoc.files)[0])
        } else {
            let fileParam = searchParams.get('file')
            setCurrentFile(fileParam)
        }

        let header = document.getElementById('usecase-navigate')
        if (header) header.textContent = currentUseCase.name

        setEditSimpleAnnotation(null)
        setAddSimpleAnnotationMode(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams, currentDoc])

    const [imageAttrs, setImageAttrs] = React.useState()
    const [OCR, setOCR] = React.useState()
    React.useEffect(() => {

        const fetchPageData = async () => {
            await API.getFileInference(loaderData['usecaseID'], loaderData['documentID'], currentFile).then(
                async res => {
                    setOCR(res.data['ocr'])
                    setInferenceResult(res.data);
                    setImageAttrs(res.data['page_layout'])
                    if (justSeen) return;
                    trackJune(analytics, JuneEvents.AHA)
                    setJustSeen(true)

                }
            ).catch((e) => null)

        }
        let header = document.getElementById('usecase-nav-fileName')
        let headerStatus = document.getElementById('usecase-nav-revisionStatus')
        currentDoc && currentFile && fetchPageData()
        if (currentDoc && header) {
            header.textContent = currentDoc.document_metadata.original_name
            headerStatus.textContent = revisionMapping[currentDoc.revision_status].text
            headerStatus.style.borderColor = revisionMapping[currentDoc.revision_status].border
            headerStatus.style.backgroundColor = revisionMapping[currentDoc.revision_status].color

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFile])

    const [unusedLabels, setUnusedLabels] = React.useState([])

    const createUnusedLabelsList = (inf) => {
        let iR = inf['entities']
        let unused = Object.keys(iR).map((x) => {


            if (Array.isArray(iR[x]))

                return {
                    'tag': x,
                    'tag_aggregation': currentUseCase['labels'][x].tag_aggregation,
                    'tag_alias': currentUseCase['labels'][x].tag_alias,
                    'tag_multiple_values': currentUseCase['labels'][x].tag_multiple_values,
                }

            else if (Object.keys(iR[x]).length === 0)
                return {
                    'tag': x,
                    'tag_aggregation': currentUseCase['labels'][x].tag_aggregation,
                    'tag_alias': currentUseCase['labels'][x].tag_alias,
                    'tag_multiple_values': currentUseCase['labels'][x].tag_multiple_values,
                }
        })
        setUnusedLabels(unused.filter(x => x !== undefined))
    }

    React.useEffect(() => {
        if (!inferenceResult) return;
        createUnusedLabelsList(inferenceResult)
    }, [inferenceResult])



    const revisionColor = (status, alpha) => {
        let colors = {
            'approved': `rgba(0,255,0,${alpha})`,
            'not_approved': `rgba(255,0,0,${alpha})`,
            'not_ready': `rgba(112,128,144,${alpha})`,
            'to_review': `rgba(255,175,0,${alpha})`,
            'pending': `rgb(0,255,220,${alpha})`,
            'review_required': `rgb(255,230,0,${alpha})`, //no operazioni fatte: da capire come mostrare
        }

        return colors[status];
    }

    const revisionMapping = {
        'approved': { text: 'Approved', border: revisionColor('approved', 0.85), color: revisionColor('approved', 0.25) },
        'not_approved': { text: 'Rejected', border: revisionColor('not_approved', 0.85), color: revisionColor('not_approved', 0.25) },
        'not_ready': { text: 'Not Ready', border: revisionColor('not_ready', 0.85), color: revisionColor('not_ready', 0.25) },
        'to_review': { text: 'To Review', border: revisionColor('to_review', 0.85), color: revisionColor('to_review', 0.25) },
        'pending': { text: 'New', border: revisionColor('pending', 0.85), color: revisionColor('pending', 0.25) },
        'review_required': { text: 'Review Required', color: revisionColor('review_required', 0.25), border: revisionColor('review_required', 0.85) },
    }

    const updateFileStatus = async (revision_status, checkNavigate = false) => {
        await API.updateDocumentRevisionStatus(loaderData['usecaseID'], loaderData['documentID'], revision_status).then(() => {
            let updatedDoc = currentDoc
            updatedDoc['revision_status'] = revision_status
            if (checkNavigate) {
                let fromPage = searchParams.get('page')
                if (fromPage)
                    navigate({ pathname: `/usecase/${loaderData['usecaseID']}/documents`, search: '?page=' + fromPage })
                else if (location.state && location.state['fromPage'])
                    navigate({ pathname: `/usecase/${loaderData['usecaseID']}/documents`, search: '?page=' + location.state['fromPage'] })
                else
                    navigate(`/usecase/${loaderData['usecaseID']}/documents`)
            }
            else {
                let headerStatus = document.getElementById('doc-revision-status')
                headerStatus.textContent = revisionMapping[revision_status].text
                headerStatus.style.borderColor = revisionMapping[revision_status].border
                headerStatus.style.backgroundColor = revisionMapping[revision_status].color
                setCurrentDoc(updatedDoc)
            }
        }
        )
    }

    const resizeTarget = useRef()

    const refetchInferenceResult = async () => {
        await API.getFileInference(loaderData['usecaseID'], loaderData['documentID'], currentFile).then(
            async res => {
                setInferenceResult(res.data);
            }
        ).catch((e) => null)
    }

    const handleEditField = async (
        labelId,
        label,
        newField,
        value_index = 0
    ) => {
        API.updateSimpleEntity(
            loaderData["usecaseID"],
            loaderData["documentID"],
            currentFile,
            label,
            {
                id: labelId,
                bounding_boxes: null,
                text: newField,
            }
        ).then(() => {
            refetchInferenceResult()
        });
    };

    const handleAddField = async (label, newField, bounding_boxes, confidence) => {
        API.addSimpleEntity(
            loaderData["usecaseID"],
            loaderData["documentID"],
            currentFile,
            label,
            {
                text: newField,
                bounding_boxes: bounding_boxes,
                confidence: confidence,
            }
        ).then(() => {
            refetchInferenceResult()
        });
    }

    const navigateToPage = (page) => {
        if (page === currentFile) return
        searchParams.set('file', page)
        setInferenceResult(null)
        navigate({ pathname: `/usecase/${loaderData['usecaseID']}/result/${loaderData['documentID']}`, search: '?' + searchParams.toString() }, { state: { 'currentUseCase': currentUseCase } })
    }

    const [docList, setDocList] = React.useState([])
    const [lastPage, setLastPage] = React.useState(-1)
    const [currentPage, setCurrentPage] = React.useState(-1)

    const fetchDocList = async () => {
        let docs = await API.listAllDocuments(currentUseCase['public_id'], searchParams.get('revision') === 'All' ? '' : searchParams.get('revision'), searchParams.get('page'))
        setDocList(docs['items_data'].map((d) => d['public_id']))
        setLastPage(docs['last_page'])
        setCurrentPage(docs['current_page'])
    }

    useEffect(() => {
        currentUseCase && fetchDocList()
    }, [location])

    const [stageSize, setStageSize] = React.useState({ width: 650, height: 650 });

    useResizeObserver(resizeTarget.current, entry => {
        const { inlineSize: width, blockSize: height } = entry.contentBoxSize[0];
        setStageSize({ width, height })
    })

    const fetchInferenceResult = async () => {
        await API.getFileInference(loaderData['usecaseID'], loaderData['documentID'], currentFile).then(
            async res => {
                setInferenceResult(res.data);
            }
        ).catch((e) => null)
    }

    const handleAddNewAnnotation = async (entity) => {
        const data = await API.addSimpleEntity(currentUseCase.public_id, currentDoc.public_id, currentFile, entity.label, entity).then((res) => {
            fetchInferenceResult()
            notification.addNotification({
                title: 'Success',
                message: 'Field successfully created',
                severity: 'SUCCESS',
                timeout: 3000
            })
            return res
        }).catch(e => {
            notification.addNotification({
                title: 'Error',
                message: e.response.data.detail[0].msg,
                severity: 'ERROR',
                timeout: 3000
            })
        });
        return data
    }


    const handleEditAnnotation = async (entity) => {
        await API.updateSimpleEntity(currentUseCase.public_id, currentDoc.public_id, currentFile, entity.label, entity).then(async (res) => {

            fetchInferenceResult()
            notification.addNotification({
                title: 'Success',
                message: 'Field successfully updated',
                severity: 'SUCCESS',
                timeout: 3000
            })
            /*
            let toEditInferenceResult = inferenceResult

            toEditInferenceResult[entity.label] = {
                'bounding_boxes': entity.bounding_boxes,
                'confidence': inferenceResult[entity.label].confidence,
                'id': Number(entity.id),
                'text': entity.text
            }

            setInferenceResult(toEditInferenceResult)*/

            return res
        }).catch(e => {

            notification.addNotification({
                title: 'Error',
                message: e.response.data.detail[0].msg,
                severity: 'ERROR',
                timeout: 3000
            })
        });
    }

    const [editSimpleAnnotation, setEditSimpleAnnotation] = React.useState(null)


    const handleDeleteAnnotation = async (entity) => {


        let res = await API.deleteSimpleEntity(
            currentUseCase.public_id,
            currentDoc.public_id,
            currentFile,
            entity.label,
            entity.id
        ).then(() => {
            fetchInferenceResult();
            notification.addNotification({
                title: 'Success',
                message: 'Field successfully deleted',
                severity: 'SUCCESS',
                timeout: 3000
            })
        }).catch(e => {
            notification.addNotification({
                title: 'Error',
                message: e.response.data.detail,
                severity: 'ERROR',
                timeout: 3000
            })
        });

        return res;
    };

    const [defaultAddLabel, setDefaultAddLabel] = React.useState('')
    const stageContext = useContext(StageContext)

    window.addEventListener('mouseover', (e) => {
        if (!stageContext) return;
        let target = e.target;
        if (target.id !== 'label-anchor' && stageContext.highlighted) stageContext.setHighlighted(null)

    })

    return (
        <StageProvider>
            <div className="flex flex-row h-full grow">
                {
                    currentDoc &&
                    <ResultGallery
                        files={currentDoc.files}
                        currentFile={currentFile}
                        navigateToPage={navigateToPage} />
                }
                <div ref={resizeTarget} id='result-canvas' name="canvas" className="relative flex grow min-w-[100px] bg-[#EDEDF8]">
                    <div className="absolute z-30 !w-full flex justify-end h-fit !pointer-events-none">
                        <div className="w-fit shadow-md h-fit px-2 py-1 bg-white rounded-myb !pointer-events-auto !transition-all !duration-300 !ease-out">
                            Page: {(currentFile && currentDoc) ? Object.keys(currentDoc.files).indexOf(currentFile) + 1 : ''} / {currentDoc && Object.keys(currentDoc.files).length}
                        </div>
                    </div>
                    {

                        currentDoc && currentFile && inferenceResult &&
                        <ProductResultCanvas
                            imageAttrs={imageAttrs}
                            setStageSize={setStageSize}
                            stageSize={stageSize}
                            labels={currentUseCase['labels']}
                            inferenceResult={inferenceResult['entities']}
                            fileURL={currentDoc.files[currentFile]}
                            handleAddNewAnnotation={handleAddNewAnnotation}
                            handleEditAnnotation={handleEditAnnotation}
                            handleDeleteAnnotation={handleDeleteAnnotation}
                            defaultAddLabel={defaultAddLabel}
                            setAddSimpleAnnotationMode={setAddSimpleAnnotationMode}
                            addSimpleAnnotationMode={addSimpleAnnotationMode}
                            editSimpleAnnotation={editSimpleAnnotation}
                            OCR={OCR}
                        />
                    }
                </div>
                {


                    <div name="result" className="flex h-full bg-[#F9F9F9] pt-2 items-center justify-center max-w-[275px] min-w-[275px]   md:!max-w-[350px] md:!min-w-[350px] xl:max-w-[400px] xl:!min-w-[400px] 2xl:!max-w-[500px] 2xl:!min-w-[500px] flex-col px-2 gap-2">
                        {
                            currentUseCase && currentUseCase.public_id && currentDoc && docList && docList.length > 0 &&
                            <DocControlPanel
                                revisionMapping={revisionMapping}
                                currentDoc={currentDoc}
                                docList={docList}
                                pageIndex={docList.findIndex((x) => x === currentDoc.public_id)}
                                lastPage={lastPage}
                                currentPage={currentPage}
                                useCaseID={currentUseCase.public_id}
                                navigateTo={navigateTo}
                                searchParams={searchParams}
                            />
                        }
                        <ResultList
                            setAlertData={setAlertData}
                            currentUseCase={currentUseCase}
                            inference={inferenceResult}
                            unusedLabels={unusedLabels}
                            handleEditField={handleEditField}
                            handleDeleteAnnotation={handleDeleteAnnotation}
                            refetchInferenceResult={refetchInferenceResult}
                            setDefaultAddLabel={setDefaultAddLabel}
                            setEditSimpleAnnotation={setEditSimpleAnnotation}
                            editSimpleAnnotation={editSimpleAnnotation}
                            setAddSimpleAnnotationMode={setAddSimpleAnnotationMode}
                            handleEditAnnotation={handleEditAnnotation}
                            handleAddNewAnnotation={handleAddNewAnnotation}
                        />

                        <ResultFooter updateFileStatus={updateFileStatus} />


                    </div>
                }
            </div>
        </StageProvider>
    )
}